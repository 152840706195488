
import { reactive, toRefs, onBeforeMount, onMounted } from 'vue'
import 'amfe-flexible'
import { useRoute } from 'vue-router'
import { getQunCoinInfoV2 } from '@/api/domain'
export default {
  name: 'businessDetail',
  setup() {
    const route = useRoute()
    const data = reactive({
      userId: route.query.id,
      info: {} as any
    })
    console.log(data.userId)
    getQunCoinInfoV2({ userId: data.userId }).then((res: any) => {
      console.log(res)
      data.info = res.data
    })
    const refData = toRefs(data)
    return {
      ...refData
    }
  }
}
